const HomePage = () => import('@/modules/home/pages/HomePage.vue')

export default [
  {
    name: 'home',
    path: 'home',
    component: HomePage,
    meta: { auth: true, emailVerified: true, tenant: true, init: true }
  }
]
