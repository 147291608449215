import { createRouter as createVueRouter, createWebHistory } from 'vue-router'
import modules from '@/modules'
import ProgressBar from '@/shared/components/ProgressBar/progress-bar'
import authRoutes from '@/modules/auth/auth-routes'
import middlewareArray from '@/middleware'

const moduleRoutesExceptAuth = Object.keys(modules)
  .filter((key) => Boolean(modules[key].routes) && !['auth'].includes(key))
  .map((key) => modules[key].routes)
  .reduce((a, b) => a.concat(b), [])

/**
 * Loads all the routes from src/modules/ folders, and adds the catch-all rule to handle 404s
 */
const routes = [
  {
    path: '/',
    component: () => import('@/modules/layout/components/DefaultLayout.vue'),
    redirect: '/home',
    children: [...moduleRoutesExceptAuth],
    meta: { auth: true }
  },
  ...authRoutes,
  { path: '/*', redirect: '/404' }
]

let router

/**
 * Creates/Sets Router
 */
export const createRouter = () => {
  if (!router) {
    router = createVueRouter({
      history: createWebHistory(),
      routes,
      scrollBehavior() {
        return { x: 0, y: 0 }
      }
    })

    const originalPush = router.push
    router.push = function push(location) {
      return originalPush.call(this, location).catch((error) => {
        console.error(error)
        ProgressBar.done()
      })
    }

    router.beforeEach(async (to, from) => {
      if (to.name && to.name !== from.name) {
        ProgressBar.start()
      }

      const matchedRoute = to.matched
      if (matchedRoute !== undefined) {
        const context = {
          from,
          router,
          to
        }

        try {
          for (const middleware of middlewareArray) {
            await middleware(context)
          }
        } catch (redirectTo) {
          // Some middleware failed the validation, and a redirect was thrown
          return { name: redirectTo.name, params: redirectTo.params, query: redirectTo.query }
        }
      }
    })

    router.afterEach(() => {
      ProgressBar.done()
    })
  }

  return router
}

export { router }
